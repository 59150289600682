import React, {Fragment} from 'react';
import Helmet from 'react-helmet';
import {graphql} from 'gatsby';

import Layout from '@layouts';
import Section from '@components/layout/Section';
import Hero from '@components/Hero';
import ImageCallout from '@components/ImageCallout';
import PreFooter from '@components/PreFooter';
import {stringToUrl, stripTagsFromWagtail, propertyHasUnits} from '@util';

import type {PropertyType} from '@types-local';

type Props = {
  data: {
    wagtail: {
      ...PropertyType[],
    },
  },
};

function getDescriptionBasedOnNeighborhoodName(name) {
  switch (name.toLowerCase()) {
    case 'nomad':
      return 'NoMad isn’t just in the heart of New York City, it’s the pulse. You’re in the center of everything you love. Whether that’s the best in fashion and food or it’s a recharging walk through the park on your convenient commute, life in NoMad truly makes a connection to you and your world.';
    case 'upper east side':
      return 'There’s just something different about life on the UES. This is where you’ll find the best of both worlds through some of the city’s finest parks, a bar and restaurant scene that mixes a perfect blend between old and new, and the convenience of the Q train. You’ll find no shortage of small-town charm with big time possibilities here.';
    case "hell's kitchen":
      return 'Find yourself in Hell’s Kitchen, where everything you love about New York City surrounds you. From easy subway access to move effortlessly around the city, to lively food and entertainment options on every corner, to the Highline, Hell’s Kitchen is everything authentic New York City fit into a few square blocks.';
  }
}

function getIndexForNeighborhoods(neighborhoodName){
  if (neighborhoodName){
    switch (neighborhoodName.toLowerCase()) {
      case "nomad":
        return 0;
      case "upper east side":
        return 1;
      case "hell's kitchen":
        return 2;
      default:
        return 0;
    }
  }
}

export default ({data}: Props) => {
  const {page, properties} = data.wagtail;
  const {
    seoTitle,
    searchDescription,
    heroHeadline,
    heroCtaText,
    heroCopy,
    heroImage,
  } = page;

  const propertiesWithUnits = properties.filter(propertyHasUnits);

  // Exclude 'NoHo' and 'Hell's Kitchen' from the neighborhoods
  const excludedNeighborhoods = ['NoHo', "Hell's Kitchen"];
  const neighborhoodsWithUnits = propertiesWithUnits
  .filter(property => 
    property.address.neighborhood && 
    property.address.neighborhood.length > 0 && 
    !excludedNeighborhoods.includes(property.address.neighborhood[0].name)
  )
  .map(property => {
    const neighborhoodObject = {};
    neighborhoodObject.propertyName = property.name;
    neighborhoodObject.neighborhoodName = property.address.neighborhood[0].name;
    neighborhoodObject.slug = property.address.neighborhood[0].slug;
    neighborhoodObject.image = property.address.neighborhood[0].image;
    neighborhoodObject.description = getDescriptionBasedOnNeighborhoodName(
      neighborhoodObject.neighborhoodName,
    );
    return neighborhoodObject;
  });

  const sectionRef = React.createRef();
  
  var orderedNeighborhoods = [];
  for (let index = 0; index < neighborhoodsWithUnits.length; index++) {
    orderedNeighborhoods[getIndexForNeighborhoods(neighborhoodsWithUnits[index].neighborhoodName)] = neighborhoodsWithUnits[index];
  }

  return (
    <Fragment>
      <Helmet>
        <html lang="en" />
        <title>{seoTitle}</title>
        <meta name="description" content={searchDescription} />
      </Helmet>
      <Layout>
        <Hero
          title={heroHeadline}
          background={`linear-gradient(rgba(20, 20, 20, 0.3), rgba(20, 20, 20, 0.3)), url(${
            heroImage.url
          })`}
          body={stripTagsFromWagtail(heroCopy)}
          scrollToLink
          copy={heroCtaText}
          sectionRef={sectionRef}
        />
        <div id="second-section" ref={sectionRef}></div>
        {orderedNeighborhoods &&
          orderedNeighborhoods.length > 0 &&
          orderedNeighborhoods.map((neighborhood, index) => (
            <Section key={neighborhood.neighborhoodName}>
              <ImageCallout
                headline={neighborhood.neighborhoodName}
                eyebrow={!['Upper East Side', "Hell's Kitchen"].includes(neighborhood.neighborhoodName) ? neighborhood.propertyName : ''}
                copy={neighborhood.description}
                imageLeft={index % 2 === 0 ? false : true}
                imageSrc={neighborhood.image && neighborhood.image.url}
                imgMaxHeight="600px"
                imgMaxWidth="50%"
                textAlign="left"
                button={{
                  buttonLink: `/neighborhoods/${stringToUrl(
                    neighborhood.neighborhoodName,
                  )}/`,
                  buttonText: `Check out ${neighborhood.neighborhoodName}`,
                }}
              />
            </Section>
          ))}
        <PreFooter />
      </Layout>
    </Fragment>
  );
};

export const query = graphql`
  fragment NeighborhoodsLandingFragment on Wagtail_NeighborhoodLandingPage {
    seoTitle
    searchDescription
    heroCopy
    heroHeadline
    heroImage {
      url
    }
    heroCtaText
  }
  query {
    wagtail {
      page(slug: "neighborhoods") {
        ...NeighborhoodsLandingFragment
      }
      properties {
        name
        soldout
        address {
          neighborhood {
            slug
            name
            image {
              url
            }
          }
        }
        floorplans {
          units {
            available
          }
        }
      }
    }
  }
`;